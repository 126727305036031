<template>
  <LPSuggestionsSimilarCarouselSkeleton
    v-if="loading"
    :number-to-show="chunkSize"
    class="similar-listings-container"
  />

  <div v-else-if="similarListings?.length" class="listing-similars bg-util-3 q-py-xl">
    <div class="similar-listings-container">
      <div class="l-similar-title" v-text="t('section.similar.title')" />
      <div class="suggestions-carousel">
        <q-carousel
          v-model="slide"
          transition-prev="slide-right"
          transition-next="slide-left"
          keep-alive
          :swipeable="$q.screen.lt.md"
          animated
          :padding="showArrows"
          control-color="black"
          :control-type="controlType"
          infinite
          height="100%"
          :arrows="showArrows"
          :class="carouselClasses"
        >
          <template v-if="hasMultipleChunks" #control>
            <div class="btn-navigation q-px-md">
              <template v-for="i in chunkedListings.length" :key="i">
                <q-icon
                  class="cursor-pointer"
                  :color="slide === i - 1 ? 'primary' : 'util-2'"
                  name="lens"
                  size="24px"
                  @click="changeSlideIndex(i - 1)"
                />
              </template>
            </div>
          </template>

          <q-carousel-slide
            v-for="(items, index) in chunkedListings"
            :key="index"
            :name="index"
            class="suggestions-carousel--listings q-py-lg3"
          >
            <div v-for="(item, idx) in items" :key="idx">
              <SPListItem
                :listing="ListingMapper.fromSearchResult(item)"
                class="fit listing-item--similar-suggestion"
              />
            </div>
          </q-carousel-slide>
        </q-carousel>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import chunk from 'lodash/chunk';
import { storeToRefs } from 'pinia';
import { type QCarouselProps, Screen } from 'quasar';
import { computed, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import LPSuggestionsSimilarCarouselSkeleton from '@/components/ListingPage/fragments/LPSuggestionsSimilarCarouselSkeleton.vue';
import SPListItem from '@/components/SearchPage/List/Item/SPListItem.vue';
import { useApiListing } from '@/composables/api/listing';
import translations from '@/i18n/translations/components/listingPage.json';
import ListingMapper from '@/mappers/listingMapper';
import { useListingStore } from '@/store/modules/listing';
import type { SearchResultItem } from '@/types';

withDefaults(
  defineProps<{
    containerMaxWidth?: string;
    carouselClasses?: string;
    controlType?: QCarouselProps['controlType'];
  }>(),
  {
    carouselClasses: 'bg-transparent',
    controlType: 'outline',
  }
);

const { t } = useI18n(translations);

const { indexSimilars } = useApiListing();

const { listing } = storeToRefs(useListingStore());
const loading = ref(false);
const similarListings = ref<SearchResultItem[]>();
const slide = ref(0);
const desktopChunkSize = 3;
const tabletChunkSize = 2;

const changeSlideIndex = (i: number) => {
  slide.value = i;
};

const chunkSize = computed(() => {
  if (Screen.sm) {
    return tabletChunkSize;
  }

  if (Screen.xs) {
    return 1;
  }

  return desktopChunkSize;
});

const chunkedListings = computed(() => {
  return chunk(similarListings.value, chunkSize.value);
});

const hasMultipleChunks = computed(() => chunkedListings.value.length > 1);

const showArrows = computed(() => Screen.gt.sm && hasMultipleChunks.value);

const arrowPaddings = computed(() => {
  if (showArrows.value) {
    return '65px';
  }

  return '4px';
});

const containerPaddings = computed(() => {
  if (Screen.lt.md) {
    return '16px';
  }

  if (Screen.md && !showArrows.value) {
    return '48px';
  }

  if (Screen.width >= 1440 && Screen.width <= 1560 && !showArrows.value) {
    return '48px';
  }

  if (Screen.width >= 1550 && Screen.width <= 1920 && showArrows.value) {
    return '0';
  }

  if ((showArrows.value && Screen.lg) || (showArrows.value && Screen.md)) {
    return '48px';
  }

  return '0';
});

const getSimilarListings = async () => {
  if (!listing.value) return;

  loading.value = true;

  indexSimilars(listing.value.id)
    .then(({ data }) => {
      similarListings.value = data.data;
    })
    .catch(e => {
      console.error(e);
    })
    .finally(() => {
      loading.value = false;
    });
};

onMounted(() => {
  getSimilarListings();
});
</script>

<style lang="scss">
.suggestions-carousel {
  overflow: hidden;

  .suggestions-carousel--listings {
    display: grid;
    grid-template-columns: repeat(v-bind('chunkSize'), minmax(0, 1fr));
    gap: 1rem;
  }

  .btn-navigation {
    position: relative;
    right: 0;
    bottom: 0;
    left: 0;
    text-align: center;
  }

  .q-btn__content .q-icon {
    font-size: 14px;
  }

  .q-carousel {
    overflow: hidden;
  }

  .q-btn--outline:before {
    background-color: $white;
    border: 1px solid $util-2;
  }

  .q-carousel .q-carousel__next-arrow--horizontal {
    right: 0;
  }

  .q-carousel .q-carousel__prev-arrow--horizontal {
    left: 0;
  }

  .q-carousel .q-carousel__slide {
    padding-right: v-bind('arrowPaddings');
    padding-left: v-bind('arrowPaddings');
  }
}

.similar-listings-container {
  width: 100%;
  padding: 0 v-bind('containerPaddings');
  margin: 0 auto;

  .l-similar-title {
    font-size: 2rem;
    font-weight: 800;
    line-height: 1.4;
    text-align: center;
  }
}
</style>
